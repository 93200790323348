#cash-register {

  #pending-tickets{
    width: 400px;
  }

  .page {
    background-color: #1b2434;
  }

  .navbar {
    background: #1e293b;
    color: rgba(255, 255, 255, .7);

    a {
      color: rgba(255, 255, 255, .7);
      border-color: rgb(44, 60, 86);

      &:hover {
        border-color: rgba(55, 70, 95, 0.95);
      }
    }

    img {
      filter: brightness(0) invert(1);
    }
  }

  .pane {
    .card {
      height: calc(100vh - 100px);
    }
  }

  #search-pane {

  }

  #action-pane {
    .action-pad {
      height: 40%;

      table {
        height: 100%;

        td {
          width: 50%;
          height: 33.333333%;
        }

        button, a {
          border-radius: 0;
          width: 100%;
          height: 100%;
          border: 0;
          font-weight: bold;
          font-size: 1.2rem;
        }
      }
    }

    .screen-pad {
      height: 10%;

      input {
        height: 100%;
        font-size: 2.5rem;


        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        &[type=number] {
          -moz-appearance: textfield;
        }
      }
    }

    #numpad-keyboard {
      height: 50%;

      table {
        height: 100%;

        td {
          width: 25%;
          height: 20%;
        }

        button {
          width: 100%;
          height: 100%;
          border: 0;
          font-weight: bold;
          font-size: 1.3rem;
        }

        .number {
          font-size: 1.3rem;
        }
      }
    }

  }

  #ticket-pane {
    font-family: monospace, sans-serif;

    overflow-x: hidden;


    .ticket-divider {
      border-top: 1px dashed;
      width: 100%;
      height: 1px;
    }

    table.products {

      tr {
        &.product {
          &.active {
            background-color: rgba(27, 36, 52, 0.3);

            &:hover {
              background-color: rgba(27, 36, 52, 0.35);
            }
          }

          &:hover {
            background-color: rgba(0, 0, 0, 0.075);
          }
        }


        td {
          position: relative;
          max-width: 1px;

          .label {
            text-overflow: ellipsis;
            overflow-x: hidden;
            white-space: nowrap;
          }

          .detail {
            width: 70%;
          }

          .total {
            width: 30%;
          }
        }
      }
    }

    .total-box {
      background-color: #FFFFFF;
    }

    .card {
      border: 0;
      border-radius: 0;
      margin-top: 10px;
      height: calc(100vh - 120px);

      &:before {
        content: "";
        position: absolute;
        display: block;
        height: 10px;
        top: -10px;
        left: 0;
        right: 0;
        background: linear-gradient(45deg, #FFFFFF 33.333%, transparent 33.333%, transparent 66.667%, #FFFFFF 66.667%),
        linear-gradient(-45deg, #FFFFFF 33.333%, transparent 33.333%, transparent 66.667%, #FFFFFF 66.667%);
        background-size: 8px 20px;
        background-position: 0 -10px;
      }

      &:after {
        content: "";
        position: absolute;
        display: block;
        height: 10px;
        bottom: -10px;
        left: 0;
        right: 0;
        background: linear-gradient(45deg, transparent 33.333%, #FFFFFF 33.333%, #FFFFFF 66.667%, transparent 66.667%),
        linear-gradient(-45deg, transparent 33.333%, #FFFFFF 33.333%, #FFFFFF 66.667%, transparent 66.667%);
        background-size: 8px 20px;
        background-position: 0 -10px;
      }

      .card-body {
        overflow-y: scroll;
        padding-bottom: 140px !important;
      }
    }
  }
}

#modal-payment-ticket {
  #settlement_type {
    label {
      &.is-invalid {
        .form-selectgroup-label{
          color: #d63939;
          border-color: #d63939;
        }
      }
    }
  }
}