.autocomplete {
  position: relative;

  ul {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 10000;

    li {
      cursor: pointer;
      background-color: white;

      &:hover {
        background-color: lightgray;
      }
    }
  }
}

.select-autocomplete {
  .results {
    ul {
      overflow-x: hidden;
      overflow-y: scroll;
      max-height: 320px;
    }
  }
}
