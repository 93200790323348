#inventory {
  form.inventory-line-form {
    .quantity {
      width: 7%;
    }
  }

  table {
    &.table-hover {
      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  }
}
