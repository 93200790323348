#provider-order {
  .page-header {
    .btn.icon-btn {
      font-size: 20px;
    }

    .dropdown {
      .btn-link {
        color: black;
        border-color: transparent;

        &:hover, &:focus {
          box-shadow: none;
          text-decoration: none;
          background-color: lightgray;
          border-color: lightgray;
        }
      }
    }
  }
}
