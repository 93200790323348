.container.sales {
  table{
    thead{
      .form-check{
        margin: 0;
      }
    }

    th, td {
      &.comment {
        max-width: 203px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}