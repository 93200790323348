@use "autocomplete";
@use "dashboard";
@use "sales";
@use "sale";
@use "provider-order";
@use "cash-register";
@use "inventory";

@import "@tabler/core/dist/css/tabler.min.css";
@import "@tabler/icons-webfont/dist/tabler-icons.min.css";

button.remove_vehicle_button{
  margin-top: 28px;
}

table.product-images {
  .ti-photo-off{
    font-size: 110px;
  }
}