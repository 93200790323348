#sale {
  .page-header {
    .btn.icon-btn {
      font-size: 20px;
    }

    .dropdown {
      .btn-link {
        color: black;
        border-color: transparent;

        &:hover, &:focus {
          box-shadow: none;
          text-decoration: none;
          background-color: lightgray;
          border-color: lightgray;
        }
      }
    }
  }

  .transmitter {
    background-color: rgba(248, 249, 250);
  }

  .head {
    img {
      height: 80px;
    }
  }

  .recipient {
    border: 1px solid gray;
  }

  table.products {
    tr {
      &.editable-line {
        &:hover {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.075);
        }
      }

      .delete {
        top: 2px;
        right: -35px;
        border: 0;

        a {
          color: rgba(0, 0, 0, 0.2);

          &:hover {
            color: rgba(0, 0, 0, 0.4);
            text-decoration: none;
          }

          i {
            font-size: 18px;
          }
        }
      }

      .handle {
        font-size: 20px;
      }
    }
  }

  .type-line-form {
    width: 12%;
  }

  .free_input-line-form {
    .vat,
    .quantity {
      width: 7%;
    }

    .price,
    .discount {
      width: 10%;
    }
  }

  .product-line-form {
    .vat,
    .quantity {
      width: 7%;
    }

    .price,
    .discount {
      width: 10%;
    }
  }

  .vehicle-line-form {

    .mileage,
    .brand{
      width: 10%;
    }

    .model{
      width: 50%;
    }
  }
}

#payment {
  tr.update-settlement-trigger {
    &:hover {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

#modal-print-sale {
  iframe {
    height: calc(100vh - 230px);
  }
}

#modal-credit {
  tr.unchecked {
    td {
      &:not(:first-child) {
        opacity: 0.5;
        user-select: none;
      }

      &.quantity {
        input {
          pointer-events: none;
        }
      }
    }
  }
}
