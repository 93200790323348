
@keyframes spinner {
  to { transform: rotate(360deg); }
}

.ti-loader-2 {
  font-size: 4rem;
  animation: spinner 1.5s linear infinite;
}

#chart {
  min-height: 240px;
}

form[name=period-selector] {
  input[type=radio]:checked + label.btn {
    background-color: #206bc4;
    border-color: #206bc4;
    color: white;
  }
}
